import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { LaunchDarklyIdentityTypes } from 'src/lib/launchDarkly/types';

export type AmplitudeHelpersShouldTrackUserParams = {
  ldIdentityType: LaunchDarklyIdentityTypes;
  excludeRouteFromTracking: boolean;
  amplitudeConfiguration: {
    trackingEnabled: boolean;
    trackAnonymousUsers: boolean;
    trackEmbeddedUsers: boolean;
  };
};

export const shouldOptOutOfAmplitude = (
  args: AmplitudeHelpersShouldTrackUserParams,
) => {
  return !shouldAmplitudeTrackUser(args);
};

const shouldAmplitudeTrackUser = ({
  ldIdentityType,
  excludeRouteFromTracking,
  amplitudeConfiguration,
}: AmplitudeHelpersShouldTrackUserParams) => {
  if (
    ldIdentityType === LaunchDarklyIdentityTypes.unknown ||
    excludeRouteFromTracking ||
    !amplitudeConfiguration.trackingEnabled
  ) {
    return false;
  }

  if (isEmbeddableSandboxRoute()) {
    return amplitudeConfiguration.trackEmbeddedUsers;
  }

  if (ldIdentityType === LaunchDarklyIdentityTypes.anonymous) {
    return amplitudeConfiguration.trackAnonymousUsers;
  } else {
    return true;
  }
};
