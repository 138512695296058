import { IntrospectionFailure } from 'src/app/graph/explorerPage/hooks/useExplorerState/useSchema/useSchemaFromEndpointIntrospection/useSchemaFromEndpointIntrospection';
import { getAmplitudeApplicationMode } from 'src/lib/analytics/amplitude/helpers/getAmplitudeApplicationMode';
import { ampli } from 'src/lib/analytics/amplitude/vendor';

type IntrospectionRequestStatus = string | IntrospectionFailure | undefined;

let previousIntrospectionRequestStatus: IntrospectionRequestStatus;

export const trackIntrospectionRequestStatus = (
  latestInstrospectionRequestStatus: IntrospectionRequestStatus,
) => {
  if (
    latestInstrospectionRequestStatus === previousIntrospectionRequestStatus
  ) {
    return;
  }

  if (latestInstrospectionRequestStatus === 'success') {
    ampli.endpointIntrospectionSuccess({
      'Application Mode': getAmplitudeApplicationMode(),
    });
  } else {
    ampli.endpointIntrospectionError({
      'Application Mode': getAmplitudeApplicationMode(),
      'Error Details': latestInstrospectionRequestStatus,
      'Error Message': `Failed to introspect user's API endpoint`,
      'Error Type': latestInstrospectionRequestStatus,
    });
  }

  previousIntrospectionRequestStatus = latestInstrospectionRequestStatus;
};
