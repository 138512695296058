import { GraphQLSchema, isObjectType, isScalarType } from 'graphql';
import { isEqual, throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsAppInBackgroundContext } from 'src/app/appLayout/hooks/useIsAppInBackground';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { trackIntrospectionRequestStatus } from 'src/lib/analytics/amplitude/helpers/trackIntrospectionRequestStatus';

import {
  SchemaResponse,
  SubgraphSdlResponse,
  defaultSchemaBuilder,
  defaultSchemaLoader,
  defaultSubgraphSdlAndSchemaLoader,
} from './schemaLoader';

export type IntrospectionFailure =
  // No endpoint has been set
  | 'noEndpointProvided'
  // Endpoint is unreachable
  | 'unableToReachEndpoint'
  // Endpoint is reachable but introspection response was not a valid graphql
  // response
  | 'invalidIntrospectionResponse'
  // Endpoint is reachable but introspection is disabled
  | 'introspectionDisabled'
  // Endpoint is reachable but schema returned from introspection request is
  // invalid
  | 'invalidIntrospectionSchema';

export function useSchemaFromEndpointIntrospection({
  uri: uriProp,
  stableHeaders,
  includeCookies: propIncludeCookies,
  skip,
  skipPolling,
  pollInterval = 10 * 1000,
  shouldPostMessageRequests = false,
  pollForSubgraphSdlFirst,
  updateOnConfigChange,
}: {
  uri?: string | null;
  stableHeaders?: Record<string, string>;
  includeCookies?: boolean | 'cycle-on-unreachable';
  skip?: boolean;
  skipPolling?: boolean;
  pollInterval?: number;
  shouldPostMessageRequests?: boolean;
  pollForSubgraphSdlFirst: boolean;
  updateOnConfigChange: boolean;
}) {
  // TODO: Move this debouncing out of this function & into the
  // places where it is consumed. We should be debouncing the typing,
  // not the side effects of typing
  const uri = useDebouncedValue(uriProp, 500, true);
  const uriPropRef = useRef(uriProp);
  uriPropRef.current = uriProp;
  const [graphQLSchema, setGraphQLSchema] = useState<GraphQLSchema>();
  // if this endpoint is a subgraph, we will return the original subgraph sdl
  // in addition to the graphql schema with added directives
  const [subgraphSdl, setSubgraphSdl] = useState<string>();

  const appIsInBackground = useIsAppInBackgroundContext();

  // Default loading states to true to avoid a flash of empty state
  // Whether the initial request for the schema has been completed
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  // Whether there is an in progress request for the schema
  const [isRefreshing, setIsRefreshing] = useState(true);
  // As soon as the first request finishes, set loading & isInitialLoading to false
  useEffect(() => {
    if (!isRefreshing) {
      setIsInitialLoading(false);
      setIsLoading(false);
    }
  }, [isRefreshing]);

  // The currentIntrospectionSchema is in a ref to avoid putting it in the dependency array of `refreshSchema`
  // which is in the dependency array of the useEffect responsible for fetching the schema once on load.
  // If the currentIntrospectionSchema changes and causes that useEffect to trigger another refetch, we
  // refetch the schema everytime the schema changes
  const currentIntrospectionSchemaRef = useRef<object>();
  const lastValidGraphQLSchemaRef = useRef<GraphQLSchema>();

  const [introspectionFailureType, setIntrospectionFailureType] = useState<
    IntrospectionFailure | undefined
  >();
  const [introspectionResponseErrors, setIntrospectionResponseErrors] =
    useState<SchemaResponse['errors']>();

  const includeCookiesRef = useRef<boolean>(false);

  // keep includeCookiesRef up to date with include cookies prop
  useEffect(() => {
    includeCookiesRef.current =
      propIncludeCookies === 'cycle-on-unreachable'
        ? false
        : !!propIncludeCookies;
  }, [propIncludeCookies]);

  // whenever the url changes, reset the include cookies cycle
  useEffect(() => {
    if (propIncludeCookies === 'cycle-on-unreachable')
      includeCookiesRef.current = false;
  }, [propIncludeCookies, uri]);

  const currentRequestParamState = useRef<{
    uri: string | null | undefined;
    stableHeaders: Record<string, string> | undefined;
    includeCookies: boolean | undefined;
  } | null>();
  currentRequestParamState.current = {
    uri,
    stableHeaders,
    includeCookies: includeCookiesRef.current,
  };

  // Track whether the server supports the `includeDeprecated` arguments,
  // if we pass this argument to an old server it will error
  const [
    shouldIncludeDeprecatedInputValues,
    setShouldIncludeDeprecatedInputValues,
  ] = useState(false);
  useEffect(() => setShouldIncludeDeprecatedInputValues(false), [uri]);

  // clear the graphql schema
  // every time someone changes the uri
  // it will be updated in the refresh
  useEffect(() => {
    setGraphQLSchema(undefined);
  }, [uri]);

  // if the uri or headers changed, we should be loading a new schema
  // and the loading status of the initial request should be reset
  useEffect(() => {
    if (uri) {
      // only show loading if the uri is not empty (can't try to fetch schema from empty uri)
      setIsLoading(true);
    }
  }, [uri, stableHeaders]);

  const [shouldPollForSubgraphSdl, setShouldPollForSubgraphSdl] = useState(
    pollForSubgraphSdlFirst,
  );

  useEffect(() => {
    setShouldPollForSubgraphSdl((prev) => {
      if (!prev) {
        if (!graphQLSchema) return false;
        const serviceType = graphQLSchema.getType('_Service');
        if (!isObjectType(serviceType)) {
          return false;
        }
        const sdlField = serviceType.getFields().sdl;
        if (!sdlField) {
          return false;
        }
        const sdlFieldType = sdlField.type;
        if (!isScalarType(sdlFieldType)) {
          return false;
        }
        return sdlFieldType.name === 'String';
      }
      return prev;
    });
  }, [graphQLSchema]);

  useEffect(() => {
    setShouldPollForSubgraphSdl(pollForSubgraphSdlFirst);
  }, [uri, pollForSubgraphSdlFirst]);

  const refreshSchema = useCallback(async () => {
    const reportValidationError = throttle((failureType: string) => {
      trackIntrospectionRequestStatus(failureType);
    }, 10_000);

    const setIntrospectionFailureTypeWithAnalyticsTracker = (
      failureType: IntrospectionFailure,
    ) => {
      if (failureType) {
        reportValidationError(failureType);
      }

      setIntrospectionFailureType(failureType);
    };

    // No uri is set, error early
    if (!uri) {
      setIsRefreshing(false);
      setGraphQLSchema(undefined);
      setIntrospectionFailureTypeWithAnalyticsTracker('noEndpointProvided');
      return;
    }

    // The url is debounced every 500ms.
    // If the url has changed since this function was triggered, don't send out a
    // request to an in-between url
    if (uriPropRef.current !== uri) {
      return;
    }

    // Set loading and kick off an introspection request
    setIsRefreshing(true);
    let schemaResponse: SchemaResponse | undefined;
    let subgraphSdlResponse: SubgraphSdlResponse | undefined;

    try {
      if (shouldPollForSubgraphSdl) {
        const subgraphSdlAndSchemaResponse =
          await defaultSubgraphSdlAndSchemaLoader({
            uri,
            requestOpts: {
              headers: stableHeaders,
              ...(includeCookiesRef.current ? { credentials: 'include' } : {}),
            },
            shouldPostMessageRequests,
          }).catch(() => undefined);

        schemaResponse = subgraphSdlAndSchemaResponse?.[0];
        subgraphSdlResponse = subgraphSdlAndSchemaResponse?.[1];

        if (
          subgraphSdlResponse?.errors &&
          !subgraphSdlResponse?.data?._service
        ) {
          setShouldPollForSubgraphSdl(false);
          // Return early if we tried subgraph and it didn't work
          // we will re fetch with the regular introspection request.
          // The schemaResponse will not be populated if there were errors in the fetch
          return;
        }
      } else {
        schemaResponse = await defaultSchemaLoader({
          uri,
          requestOpts: {
            headers: stableHeaders,
            ...(includeCookiesRef.current ? { credentials: 'include' } : {}),
          },
          introspectionOptions: {
            inputValueDeprecation: shouldIncludeDeprecatedInputValues,
          },
          shouldPostMessageRequests,
        });
      }
    } catch {
      // ignore error and deal with it below after the stale check
    } finally {
      setIsRefreshing(false);
    }

    // If the url has changed since the request was made, ignore this stale
    // result
    if (
      !isEqual(
        { uri, stableHeaders, includeCookies: includeCookiesRef.current },
        currentRequestParamState.current,
      )
    ) {
      return;
    }

    // If the request failed, endpoint is unreachable
    if (!schemaResponse) {
      setIntrospectionFailureTypeWithAnalyticsTracker('unableToReachEndpoint');

      // if we can't reach the endpoint & we are cycling include cookies, flip the value for next refresh
      if (propIncludeCookies === 'cycle-on-unreachable') {
        includeCookiesRef.current = !includeCookiesRef.current;
      }
      setIsRefreshing(false);
      return;
    }

    if (schemaResponse.errors) {
      schemaResponse.errors
        .slice(0, 10)
        .forEach((error) => reportValidationError(error?.message || ''));

      setIntrospectionResponseErrors(schemaResponse.errors);
    } else {
      setIntrospectionResponseErrors([]);
    }

    // If request succeeded but no data was returned, endpoint is reachable
    // but introspection failed

    // there are some endpoints where data is returned as an empty object. Ex: https://api.blockchain.getaurox.com/v1/ethereum/graphql
    if (!schemaResponse.data || !('__schema' in schemaResponse.data)) {
      // If we have errors in the response it means we assume this was a graphql
      // response which means we did hit a graphql endpoint but introspection
      // was specifically disabled
      setIntrospectionFailureTypeWithAnalyticsTracker(
        schemaResponse.errors
          ? 'introspectionDisabled'
          : 'invalidIntrospectionResponse',
      );
      setIsRefreshing(false);
      return;
    }

    // Set subgraph sdl whenever we have it. We fetch subgraph on the second
    // cycle, so this should always be set even if the schema is the same
    setSubgraphSdl(subgraphSdlResponse?.data?._service?.sdl);

    // If we have seen this schema before and successfully parsed, reset error
    // and loading, and use assume the schema has already been set
    if (
      isEqual(schemaResponse.data, currentIntrospectionSchemaRef.current) &&
      lastValidGraphQLSchemaRef.current
    ) {
      setIntrospectionFailureType(undefined);
      setIsRefreshing(false);
      setGraphQLSchema(lastValidGraphQLSchemaRef.current);
      return;
    }

    // Check introspection to see if the server supports `includeDeprecated`
    const directive = schemaResponse.data.__schema?.types.find(
      (type) => type.name === '__Directive',
    );
    if (
      directive?.kind === 'OBJECT' &&
      directive.fields
        .find((field) => field.name === 'args')
        ?.args.some((arg) => arg.name === 'includeDeprecated')
    ) {
      setShouldIncludeDeprecatedInputValues(true);
    }

    let schema: GraphQLSchema;
    try {
      schema = defaultSchemaBuilder(schemaResponse.data);
    } catch (e) {
      const schemaBuilderError = e as Error;
      // eslint-disable-next-line no-console
      console.error(schemaBuilderError.toString());

      // We have response data but it is not parseable, assuming invalid
      // introspection response
      setIntrospectionFailureTypeWithAnalyticsTracker(
        'invalidIntrospectionSchema',
      );
      setIsRefreshing(false);
      return;
    }

    // Received a valid schema
    setGraphQLSchema(schema);
    currentIntrospectionSchemaRef.current = schemaResponse.data;
    lastValidGraphQLSchemaRef.current = schema;
    trackIntrospectionRequestStatus('success');
    setIntrospectionFailureType(undefined);
    setIsRefreshing(false);
    // Avoid putting vars that may change in `refreshSchema` in the dependency array.
    // `refreshSchema` is in the dependency array of the useEffect responsible for
    // fetching the schema once on load. We want to avoid constantly refetching the schema.
  }, [
    uri,
    stableHeaders,
    shouldPollForSubgraphSdl,
    shouldPostMessageRequests,
    shouldIncludeDeprecatedInputValues,
    propIncludeCookies,
  ]);

  // Refresh the schema once on load
  useEffect(() => {
    if (skip) {
      setIsRefreshing(false);
      setGraphQLSchema(undefined);
      setIntrospectionFailureType(undefined);
      return;
    }
    if (!graphQLSchema || updateOnConfigChange) {
      refreshSchema();
    }
  }, [graphQLSchema, refreshSchema, skip, skipPolling, updateOnConfigChange]);

  useEffect(() => {
    if (appIsInBackground || skipPolling || skip) {
      return;
    }

    // poll for changes to schema
    const loadSchemaIntervalId = window.setInterval(
      refreshSchema,
      pollInterval,
    );
    return () => window.clearInterval(loadSchemaIntervalId);
  }, [appIsInBackground, skip, pollInterval, refreshSchema, skipPolling]);

  return useMemo(
    () => ({
      schema: graphQLSchema,
      subgraphSdl,
      // is loading is true when we make the first fetch for every new endpoint
      isLoading,
      // is initial loading is true on load when we are making the first fetch
      isInitialLoading,
      // is refreshing is true when we are fetching the schema from a new or same endpoint
      // this is true every time we poll for changes
      introspectionFailureType,
      introspectionResponseErrors,
      refreshSchema,
    }),
    [
      introspectionFailureType,
      introspectionResponseErrors,
      graphQLSchema,
      isLoading,
      isInitialLoading,
      subgraphSdl,
      refreshSchema,
    ],
  );
}
