import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';

export const getAmplitudeApplicationMode = () => {
  if (isEmbeddableExplorerRoute()) {
    return 'embedded-explorer';
  }

  if (isEmbeddableSandboxRoute()) {
    return 'embedded-sandbox';
  }

  return 'studio';
};
